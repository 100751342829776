<template>
  <div>

    <div class="fonc">
      <img src="../assets/AboutUsimg/banner.png" class="fonc_one"  style=" ">
      <div class="title">
        <img src="../assets/AboutUsimg/109.png">
        <h3>金烽科技</h3>
        <div class="info">金色烽火 传递希望 <div class="hr"></div>
        </div>
        <img src="../assets/AboutUsimg/110.png" class="last_img">
      </div>
    </div>

    <div class="brief">
      <div class="tupian">
        <div class="title">关于我们</div>
        <div class="content">
          金烽科技是一家专门为央国企提供数字化软件技术解决方案服务商；致力为客户提供软件技术服务、大数据平台和经营管理平台等专业软件解决方案。<br>
金烽拥有成员100余人，以软件技术为核心，业务涵盖政务信息化、央国企运营管理数字化解决方案，公司服务团队遍布多个省市及地区，服务过三大运营商、诺基亚、中国人保、华夏保险、山河智能、上海市政院，浙江中控、湖南钢铁、霍尼韦尔、大唐集团等客户。<br>
自成立以来，金烽在全国设立了长沙、杭州、郑州等多个研发中心，并获得了国家高新技术企业、创新型中小企业、科技型中小企业、省专精特新中小企业等荣誉；2020年，金烽科技成功荣膺湖南省互联网企业50高成长性榜单。同时，金烽科技通过了CMMI5等认证。
        </div>
      </div>
      <!-- <img src="../assets/AboutUsimg/aboutimgone.png" > -->

    </div>

    <div>
      <div class="partner">
        <img src="../assets/AboutUsimg/aboutimgtwo.png" width="100%">
        <div class="honor">
          <p>企业荣誉</p>
          <div class="certificate">
            <img src="../assets/AboutUsimg/aboutimgseven.png" class="left-img">
            <img src="../assets/AboutUsimg/aboutimgthere.png" class="heem">
            <img src="../assets/AboutUsimg/aboutlimgfour.png" class="heem">
            <img src="../assets/AboutUsimg/aboutimgfive.png" class="heem">
            <img src="../assets/AboutUsimg/aboutimgsix.png" class="heem">
            <img src="../assets/AboutUsimg/aboutimgeight.png" class="left-img">
          </div>
        </div>

      </div>

    </div>

    <div class="cooperation">
      <p>多重执业合作资质</p>
      <div class="intelligence">
        <img src="../assets/AboutUsimg/aboutimgseven.png" class="left_img">
        <img class="cooperation_img" v-for="i in 10" :key="i" :src="require('../assets/AboutUsimg/' + i + '.png')">
        <img src="../assets/AboutUsimg/aboutimgeight.png" class="right_img">
      </div>
    </div>

    <div class="replenishment">
      <div class="from-location">
        <img src="../assets/AboutUsimg/twinimgthirteen.png" width="100%">
        <div class="frombuu" ref="contactUs" id="contactUs">
          <h4>100＋专业团队为您服务</h4>
          <table action="">
            <div class="mag-lef">
              <div class="unify">
                <font color="red" style="margin-right:5px;font-size:15px">*</font>
                <span>公司名称:</span><br>
                <input type="text" class="inp" placeholder="请输入您所在的公司的名称" name="company" v-model="company">
              </div>
              <!-- <div class="unify">
                   <span>所在城市:</span><br>
                   <input type="text"  class="inp" placeholder ="请输入您所在城市" name="city" v-model="city">
              </div> -->
              <div class="unify">
                <span>所属行业:</span><br>
                <input type="text" class="inp" placeholder="请输入您所属行业" name="industry" v-model="industry">
              </div>
              <div class="unify">
                <font color="red" style="margin-right:5px;font-size:15px">*</font>
                <span>姓名:</span><br>
                <input type="text" class="inp" placeholder="怎么称呼您" name="name" v-model="name">
              </div>
              <div class="unify">
                <font color="red" style="margin-right:5px;font-size:15px">*</font>
                <span>联系电话:</span><br>
                <input type="text" class="inp" placeholder="我们会及时跟您取得联系~" name="phone" v-model="phone">
              </div>

              <!-- <div class="unify">
                <font color="red" style="margin-right:5px;font-size:15px">*</font>
                <span>我想要</span><br>
                <div class="multiple" >
                 <input type="radio" value="数字化咨询"  name="interst" v-model="checkedNames" >数字化咨询
                <input type="radio"  name="interst" value="数据中台解决方案" v-model="checkedNames">数据中台解决方案
                <input type="radio"  name="interst" value="数字孪生" v-model="checkedNames">数字孪生
                 <input type="radio"  name="interst" value="成为合作伙伴" v-model="checkedNames">成为合作伙伴
                 </div>
              </div> -->

              <div class="unify">
                <span>我的需求</span><br>
                <textarea placeholder="您可以畅所欲言，我们都会一一解答" v-model="demand">
               </textarea>
              </div>
              <p>我们会尽快与您取得联系，请耐心等待~</p>
              <button class="unify" @click="getFromList" style="cursor: pointer;">确认提交</button>
            </div>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver'
import axios from 'axios'
export default {
  data () {
    return {
      UserList: [],
      company: '',
      city: '',
      industry: '',
      name: '',
      phone: '',
      demand: '',
      one: '',
      checkedNames: '数字化咨询'
    }
  },
  mounted () {
    this.scrollToTarget()
  },
  methods: {
    getFromList () {
      if (this.company !== '' && this.name !== '' && this.phone !== '') {
        axios.get('/Finance/Clue!saveOfficialWebsiteClues.action', {
          params: {
           company: this.company,
          industry: this.industry,
          name: this.name,
          telephone: this.phone,
          other: this.demand
         }

        }).then(res => {
          if (res.status === 200) {
            this.$message({
              message: '提交成功!',
              type: 'success'
            })
          }
          this.company = ''
          this.city = ''
          this.industry = ''
          this.name = ''
          this.phone = ''
          this.demand = ''
        })
      } else {
        this.$message.error('请填写完公司名称,姓名,联系电话!')
      }
    },
    scrollToTarget () {
      if (this.$route.query.vs) {
        // 获取目标元素的引用
        this.$nextTick(() => {
          const targetElement = this.$refs.contactUs
          const targetElements = document.getElementById('contactUs')
          console.log(targetElement, targetElements)
          // 使用原生 JavaScript 的 `scrollIntoView` 方法滚动到目标元素
          targetElement.scrollIntoView({ behavior: 'smooth' })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fonc {
  position: relative;
  font-size: 0;
  display: flex;
  justify-content: center;
.fonc_one{
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
  .title {
    position: absolute;
    top: 180px;
    width: 1396px;
    img{
      width: 46px;
      height: 28px;
    }
    h3 {
      font-size: 80px;
      color: #FF892E;
    }

    .info {
      display: flex;
      align-items: center;
      font-size: 32px;
      color: #fff;
    }

    .hr {
      display: inline-block;
      width: 192px;
      height: 2px;
      background: #fff;
      margin-left: 30px;
    }

    .last_img {
      margin-left: 510px;
    }
  }
}

.brief {
  background: #F5F7FF;
  height: 800px;
  display: flex;
  justify-content: center;

  .tupian {
    background-image: url('../assets/AboutUsimg/guanyuwm.png');
    margin-top: 59px;
    width: 1451px;
    height: 472px;
    background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
    .title {
      font-size: 36px;
      font-weight: bold;
      color: #000000;
      margin-left: 619px;
      margin-top: 88px;
    }

    .content {
      color: #475272;
      font-size: 18px;
      margin-left: 594px;
      margin-top: 17px;
      line-height: 1.5;
    }
  }
}

.partner {
  text-align: center;
  position: relative;
  font-size: 0;

  .honor {
    width: 100%;
    position: absolute;
    top: -240px;

    p {
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
      margin-bottom: 30px;
    }

    .left-img {
      width: 17px;
      height: 32px;
      margin: 0 10px 0 10px;
    }

    .certificate {
      display: flex;
      justify-content: center;
      align-items: center;

      .heem {
        width: 330px;
      }
    }
  }
}

.cooperation {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 110px;
  background: #F5F7FF;
  position: relative;
  text-align: center;

  p {
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    margin-bottom: 52px;
  }

  .intelligence {
    width: 1396px;
    padding: 0 40px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    row-gap: 40px;
    flex-wrap: wrap;

    .cooperation_img {
      width: 242px;
    }

    .left_img {
      position: absolute;
      top: 50%;
      left: 0;
      width: 17px;
      height: 32px;
      transform: translateY(-50%);
    }

    .right_img {
      position: absolute;
      top: 50%;
      right: 0;
      width: 17px;
      height: 32px;
      transform: translateY(-50%);
    }
  }

  .leftarr {
    height: 332px;
    width: 248px;
    margin: 0 24px 0 24px;
  }

  .ringhtarr {
    width: 17px;
    height: 32px;
  }
}

.replenishment {
  font-size: 0;

  .from-location {
    position: relative;
  }

  .frombuu {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    bottom: 0;

    h4 {
      font-size: 36px;
      color: #fff;
    }

  }
}

table {
  width: 979px;
  height: 793px;
  background: #FFFFFF;
  border-radius: 8px;

  .mag-lef {
    margin-left: 43px;
  }
}

.inp {
  width: 894px;
  height: 40px;
  border: 1px solid #C9C9C9;
  border-radius: 4px;
  text-indent: 16px;
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}

span {
  padding-right: 826px;
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}

.unify {
  margin-top: 35px;
}

input {
  margin-top: 12px;
  outline-color: #9CAAB4;
}

.multiple {
  font-size: 17px;

  input {
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-right: 13px;
    margin-left: 13px;
  }
}

button {
  width: 200px;
  height: 56px;
  background: #FF892E;
  opacity: 1;
  border-radius: 4px;
  margin-left: 347px;
  border: 1px solid #FF892E;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  margin-bottom: 32px;
}

textarea {
  width: 894px;
  height: 103px;
  background: #F7F7F7;
  border: 1px solid #C9C9C9;
  border-radius: 4px;
  outline-color: #9CAAB4;
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  text-indent: 16px;
  margin-top: 12px;
  resize: none;
  padding-top: 10px;
}

p {
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FF892E;
  margin-top: 20px;
}</style>
